/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderInformation {
    &-ColumnTitle {
        margin-bottom: 10px;

        span {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
        }
    }

    &-AddressContent {
        @include mobile {
            padding: 0 10px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $grey-color-12;
            margin-bottom: 2px;
        }
    }
    &-Information {
        .AddressContent {
            &-Subheading {
                margin-bottom: 8px;
                font-weight: 500;
            }
        }
    }

}
