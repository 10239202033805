/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
    --link-color: #{$primary-color-2};
    --link-hover: #{$primary-color-1};
}

.MyAccountDownloadTableRow {
    height: 36px;

    td,
    th {
        @include mobile {
            width: 100%;
        }
    }

    &-Link {
        display: block;
    }

    &-Status {
        text-transform: capitalize;
    }

    &-DownloadLink {
        margin-inline-start: 8px;
    }
}
