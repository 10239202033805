/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .LoginAccount {
    .MyAccountOverlay-ForgotPassword {
        margin-top: 8px;
    }

    .MyAccountOverlay-SignInButton {
        @include desktop {
            margin: 0;
        }
    }

    &-CreateAccount {
        margin-top: 14px;
    }
}
