/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressBook {
    margin-top: 70px;

    &-Button {
        @include desktop {
            top: -111px;
        }
    }

    .MyAccountAddressTable  {
        @include desktop {
            margin-bottom: 70px;
        }

        &:last-of-type {
            @include desktop {
                margin-bottom: 125px;
            }
        }

        .Button_likeLink {
            @include mobile {
                width: auto;
                margin: 14px auto;
                display: flex;
            }
        }
    }
}
