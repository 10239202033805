/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountTabListItem {
    &-Button {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        padding: 10px 6px;
        border-radius: 2px;
        
        @include desktop {
            min-width: 208px;
        }

        &.Button_likeLink {
            width: auto;
            min-width: unset;
            padding: 0;
            margin: 50px 6px 10px;
            min-height: unset;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
        }
    }

    &:active,
    &:hover,
    &_isActive {
        .MyAccountTabListItem-Button:not(.Button_likeLink) {
            background: $primary-color-8;
            font-weight: 500;
        }

        .MyAccountTabListItem-Button.Button_likeLink {
            font-weight: 700;
            text-decoration: none;
        }
    }
}
