/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountAddressTable {
    font-size: 14px;
    
    &-ActionBar {
        @include desktop {
            margin: 5px 2px 0;
        }

       .Button_likeLink {
            @include desktop {
                margin-inline-start: 20px;
            }
        }
    }

    thead .KeyValueTable-Heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $grey-color-5;
        background-color: transparent;
        padding: 12px 10px 20px;
    }

    tbody {
        td,
        th {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $black;
            padding: 12px 10px;
            margin: 4px 0;

            @include desktop {
                width: 50%;
            }
        }

        th {
            color: $grey-color-5;
        }

        td {
            color: $black;
        }

        tr {
            &:nth-of-type(odd) {
                background: $orange-light-color;
                border-radius: 2px;
            }
        }
    }
}
