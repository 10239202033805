/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOrderItemsTable {
    &-Headings {
        padding: 0;

        th {
            background-color: transparent;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $grey-color-5;
            text-align: left;
            padding: 10px 10px 22px;
        }
    }

    &-OrderActions {
        margin: 0;
        padding: 0;
    }

    &-Products {
        thead {
            tr {

                th,
                td {
                    &:nth-child(n+3) {
                        text-align: left;
                    }

                    &:nth-child(n+5) {
                        text-align: right;
                    }
                }
            }
        }

        tbody {
            border: 0;

            &:nth-of-type(odd) .MyAccountOrderItemsTableRow-RowWrapper {
                background-color: $grey-color-1;
            }
        }
    }
}
