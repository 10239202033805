/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountDashboard {
    @include desktop {
        margin-bottom: 130px;
    }
    &-CustomerData {
        margin-top: 20px;
    }

    &-AddressesWrapper {
        @include desktop {
            column-gap: 20px;
        }
    }

    &-Addresses {
        @include desktop {
            margin-top: 60px;
        }

        .MyAccountDashboard-BlockTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;
        }

        .Button {
            margin: 0 2px 0 5px;

            a {
                margin: 0;
            }
        }
    }

    &-BlockTitle {
        border-bottom: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        color: $black;
    }

    button + button {
        @include desktop {
            margin-inline-start: 20px;
        }
    }
}
