/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOverlay {
    .Button_likeLink {
        font-size: 14px;

        &:hover,
        &:focus {
            text-decoration: none;
            padding-left: 0;
        }
    }

    &-Additional + div,
    section {
        display: flex;
        align-items: center;
        padding-top: 1px;

        h4 {
            line-height: 24px;
            font-weight: 500;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
        
        .Button_likeLink {
            padding: 0;
            margin: 0 0 0 5px;
                width: auto;
        }
    }

    &-Heading {
        font-weight: 500;
    }

    &-Action_state_createAccount {
        .Field {
            margin-top: 14px;
        }
    }

    &-Additional {
        &_state_signIn,
        &_state_forgotPassword,
        &_state_createAccount {
            section {
                align-items: center;
                padding-top: 1px;

                &:not(:first-of-type) {
                    margin-top: 5px;
                }
            }
        }
    }

    &-SignInButton {
        &:not(.Button_likeLink) {
            margin-top: 24px;
        }
    }

    &-SignUpButton,
    &-ResetPassword {
        &:not(.Button_likeLink) {
            margin-top: 18px;
        }
    }
}
