/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccount {
    &-Wrapper {
        grid-column-gap: 41px;
    }

    &-Heading {
        @include desktop {
            font-weight: 500;
            font-size: 37px;
            line-height: 40px;
            margin-bottom: 30px;
        }
    }

    &-TabContent {
        @include desktop {
            border: 0;
            padding: 15px 0;
        }

        &_activeTab {
            &_edit {
                .Field {
                    @include desktop {
                        margin-top: 20px;
                    }
                }

                .FieldForm-Section {
                    @include desktop {
                        margin-top: -20px;
                    }
                }
            }

            &_my-wishlist {
                overflow: visible;

                .MyAccount-SubHeading {
                    color: $black;
                    font-size: 18px;
                }
            }

            &_newsletter-subscription .MyAccountNewsletterSubscription .Field {
                @include desktop {
                    margin-top: 0;
                }
            }

            &_my-orders {
                .MyAccount-Heading {
                    display: flex;
                    align-items: center;
                }

                .MyAccount-SubHeading {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    text-transform: none;
                    border: 0;
                    margin: 7px 0 0 22px;
                    background: $grey-color-1;
                    padding: 8px 2px;
                }

                .Button_likeLink {
                    width: auto;
                }
            }
        }
    }
}
