/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrder {
    &-CreationDate {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    &-Buttons {
        margin: 0 2px 30px;
        align-items: center;

        .Button_likLink {
            width: auto;
        }
    }
}
