/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOrderTotals {
    &-Wrapper {
        background-color: white;

        td,
        th {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            padding: 0;
        }

        td,
        th span {
            padding: 9px 10px;
        }

        tr {
            th {
                span {
                    @include desktop {
                        min-width: 250px;
                        display: inline-block;
                        text-align: left;
                    }
                }
            }

            td {
                @include desktop {
                    min-width: 159px;
                }
            }

            &:first-of-type {

                td,
                th span {
                    @include desktop {
                        padding: 25px 10px 9px;
                    }
                }
            }

            &:last-of-type {
                td,
                th {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                }

                td,
                th span {
                    background-color: $grey-color-1;
                }
            }
        }
    }
}
